<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Room Plan') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Plan Diagram')" name="diagram">
      <a-upload
        v-model:file-list="fileList"
        name="file"
        list-type="picture-card"
        accept="image/png,image/jpeg"
        :multiple="false"
        :before-upload="handleBeforeUploadDiagram"
        :remove="handleRemoveDiagram"
        :action="uploadDiagramAction"
        :headers="apiHeaders"
        :show-upload-list="{ showRemoveIcon: false }"
        @change="handleDiagramChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Breaker location')"
      name="roomPlan.breakerlocation"
    >
      <a-input v-model:value="form.roomPlan.breakerlocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gas Meter Type Number')"
      name="roomPlan.gasMeterTypeNumber"
    >
      <a-input v-model:value="form.roomPlan.gasMeterTypeNumber" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gas Meter Location')"
      name="roomPlan.gasMeterLocation"
    >
      <a-input v-model:value="form.roomPlan.gasMeterLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('IH Stove Type')" name="roomPlan.stoveType">
      <a-input v-model:value="form.roomPlan.stoveType" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Wifi Router Location')"
      name="roomPlan.wifiRouterLocation"
    >
      <a-input v-model:value="form.roomPlan.wifiRouterLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Wifi Accesspoint Location')"
      name="roomPlan.wifiAccesspointLocation"
    >
      <a-input v-model:value="form.roomPlan.wifiAccesspointLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Key 1')" name="roomPlan.wifiKey1">
      <a-input v-model:value="form.roomPlan.wifiKey1" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Password 1')" name="roomPlan.wifiPassword1">
      <a-input v-model:value="form.roomPlan.wifiPassword1" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Key 2')" name="roomPlan.wifiKey2">
      <a-input v-model:value="form.roomPlan.wifiKey2" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Password 2')" name="roomPlan.wifiPassword2">
      <a-input v-model:value="form.roomPlan.wifiPassword2" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Boiler Control Panel Location')"
      name="roomPlan.boilerControlPanelLocation"
    >
      <a-input v-model:value="form.roomPlan.boilerControlPanelLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Square Meter')" name="roomPlan.squareMeter">
      <a-input v-model:value="form.roomPlan.squareMeter" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Remarks regarding the bed')"
      name="roomPlan.remarksRegardingTheBed"
    >
      <a-input v-model:value="form.roomPlan.remarksRegardingTheBed" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gateway Location')"
      name="roomPlan.gatewayLocation"
    >
      <a-input v-model:value="form.roomPlan.gatewayLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Other remarks')" name="roomPlan.otherRemarks">
      <a-input v-model:value="form.roomPlan.otherRemarks" :size="size" type="text" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Room Plan": "Edit Room Plan",
    "Room Plan Diagram": "Room Plan Diagram",
    "Browse File": "Browse File",
    "Breaker location": "Breaker location",
    "Gas Meter Type Number": "Gas Meter Type Number",
    "Gas Meter Location": "Gas Meter Location",
    "IH Stove Type": "IH Stove Type",
    "Wifi Router Location": "Wifi Router Location",
    "Wifi Accesspoint Location": "Wifi Accesspoint Location",
    "Wifi Key 1": "Wifi Key 1",
    "Wifi Password 1": "Wifi Password 1",
    "Wifi Key 2": "Wifi Key 2",
    "Wifi Password 2": "Wifi Password 2",
    "Boiler Control Panel Location": "Boiler Control Panel Location",
    "Square Meter": "Square Meter",
    "Remarks regarding the bed": "Remarks regarding the bed",
    "Gateway Location": "Gateway Location",
    "Other remarks": "Other remarks",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja": {
    "Edit Room Plan": "ルームプランを編集",
    "Room Plan Diagram": "客室平面図",
    "Browse File": "閲覧ファイル",
    "Breaker location": "ブレーカー位置",
    "Gas Meter Type Number": "マイコン型番",
    "Gas Meter Location": "マイコン位置",
    "IH Stove Type": "IHコンロ型番",
    "Wifi Router Location": "Wifiルーター設置場所",
    "Wifi Accesspoint Location": "Wifi中継機設置",
    "Wifi Key 1": "Wifi①名",
    "Wifi Password 1": "Wifi①暗証番号",
    "Wifi Key 2": "Wifi②名",
    "Wifi Password 2": "Wifi②暗証番号",
    "Boiler Control Panel Location": "給湯器パネル場所",
    "Square Meter": "平米数",
    "Remarks regarding the bed": "Bedに関する備考",
    "Gateway Location": "ゲートウェイ設置場所",
    "Other remarks": "その他特記事項",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
import { getToken } from '@/utils/auth';
import { uploadableImage } from '@/utils/util';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'EditRoomPlanForm',
  mixins: [FileUploader],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        roomPlan: {
          diagram: undefined,
          breakerlocation: undefined,
          gasMeterTypeNumber: undefined,
          gasMeterLocation: undefined,
          stoveType: undefined,
          wifiRouterLocation: undefined,
          wifiAccesspointLocation: undefined,
          wifiKey1: undefined,
          wifiPassword1: undefined,
          wifiKey2: undefined,
          wifiPassword2: undefined,
          boilerControlPanelLocation: undefined,
          tablet: undefined,
          tabletMode: undefined,
          tabletLocation: undefined,
          squareMeter: undefined,
          bedRemarks: undefined,
          gatewayLocation: undefined,
          otherRemarks: undefined,
        },
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
      apiHeaders: {
        authorization: `Bearer ${getToken()}`,
      },
      fileList: [],
      diagram: undefined,
    };
  },
  computed: {
    uploadDiagramAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/rooms/${this.value.id}/diagram`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = JSON.parse(JSON.stringify(nv[k]));
        });

        if (nv && nv.roomPlan.diagram && typeof nv.roomPlan.diagram === 'string') {
          this.fileList = [uploadableImage({ path: nv.roomPlan.diagram })];
        }
      },
    },
  },
  methods: {
    handleRemoveDiagram(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.diagram = '';

      return false;
    },
    handleBeforeUploadDiagram(file) {
      const isValidSize = this.checkFileSize(file);
      this.isFileValid = false;
      if (isValidSize) {
        this.fileList = [file];
        this.diagram = file;
        this.isFileValid = true;
      }

      return false;
    },
    handleDiagramChange(info) {
      if (!this.isFileValid) {
        this.fileList = [];
        return false;
      }

      const fileList = [...info.fileList];
      this.fileList = fileList.slice(-1);

      return false;
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const { id } = this.value;

        await this.$store.dispatch('rooms/update', { id: this.value.id, ...this.form });

        if (this.diagram && typeof this.diagram !== 'string') {
          const diagramPayload = { id, file: this.diagram };

          await this.$store.dispatch('rooms/uploadDiagram', diagramPayload);
        }

        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
