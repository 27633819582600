<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Add Facility') }}
      </h3>
    </div>
    <div class="column is-12" name="facilityId">
      <InputFacility
        v-model:value="form.facilityId"
        :size="size"
        :property-id="value.propertyId"
      />
    </div>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Add Facility": "Add Facility",
"Facility Name": "Facility Name",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Add Facility": "設備追加",
"Facility Name": "設備名",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import InputFacility from '@/components/InputFacilityGroup';

export default {
  name: 'AddFacilityToRoomForm',
  components: { InputFacility },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        facilityId: [],
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        const cloned = JSON.parse(JSON.stringify(this.value));
        let facilities = cloned.facilities.length ? cloned.facilities.map((f) => f.id) : [];
        facilities = [...facilities, ...this.form.facilityId.map((f) => f.id)];
        const payload = {
          id: this.value.id,
          facilities,
        };
        await this.$store.dispatch('rooms/update', payload);
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
