<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Add Inspection List') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Inspection List Name')" name="inspectionListName">
      <a-select v-model:value="form" :size="size" option-filter-prop="key">
        <a-select-option
          v-for="inspection in inspectionList"
          :key="inspection.name"
          :value="inspection.id"
        >
          {{ inspection.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Add Inspection List": "Add Inspection List",
"Inspection List Name": "Inspection List Name",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Add Inspection List": "確認箇所リストを追加",
"Inspection List Name": "確認箇所リスト名",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AddInspectionListForm',
  emits: ['close'],
  data() {
    return {
      form: '',
      inspectionList: [],
      loading: false,
      size: 'large',
    };
  },
  computed: {
    ...mapState('rooms', ['room']),
  },
  created() {
    this.getInspectionList();
  },
  methods: {
    getInspectionList() {
      this.$store.dispatch('inspection-list/list').then((res) => {
        this.inspectionList = res.list;
      });
    },
    handleSubmit() {
      this.loading = true;
      this.$store
        .dispatch('rooms/addInspectionList', { roomId: this.room.id, inspectionId: this.form })
        .then((res) => {
          if (res.ok) {
            this.loading = false;
            this.$emit('close');
          }
        });
    },
    resetFields() {
      this.form = '';
      this.$emit('close');
    },
  },
};
</script>

<style></style>
