<template>
  <div class="section">
    <div v-if="room" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Room Details') }}
          </h2>
          <div class="columns columns-table is-gapless is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Room info') }}
              </div>
            </div>
            <div v-permission="['modify-room']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomInfoForm
                      :value="room"
                      :anshin-rooms="anshinRooms"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Code') }}
                </div>
                <div>{{ room.code }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Name') }}
                </div>
                <div>{{ room.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Type') }}
                </div>
                <div>{{ room.type }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Neppan! Room ID') }}
                </div>
                <div>{{ room.neppanRoomId }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Number') }}
                </div>
                <div>{{ room.roomNumber }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Building') }}
                </div>
                <div>{{ room.building }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Floor') }}
                </div>
                <div>{{ room.floor }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Rakuten Travel ID') }}
                </div>
                <div>
                  {{ room.rtRoomId && room.rtRoomId.length > 0 ? room.rtRoomId.join(', ') : '-' }}
                </div>
              </div>
            </div>
            <div v-if="room.anshinRoomId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Anshin Room ID') }}
                </div>
                <div>{{ room.anshinRoomId }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Capacity') }}
              </div>
            </div>
            <div v-permission="['modify-room']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomCapacityForm
                      :value="room"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Capacity') }}
                </div>
                <div>{{ room.capacity }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Beds') }}
                </div>
                <div>{{ room.totalBeds }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Single Beds') }}
                </div>
                <div>{{ room.singleBeds }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Semi-Double Beds') }}
                </div>
                <div>{{ room.semiDoubleBeds }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Double Beds') }}
                </div>
                <div>{{ room.doubleBeds }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Futons') }}
                </div>
                <div>{{ room.futons }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Bedrooms') }}
                </div>
                <div>{{ room.bedrooms }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Room Plan') }}
              </div>
            </div>
            <div v-permission="['modify-room']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomPlanForm
                      :value="room"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Plan Diagram') }}
                </div>
                <div v-if="room.roomPlan.diagram">
                  <a
                    :href="$filters.imageUrl(room.roomPlan.diagram)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{ $t('show image') }}</a>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Breaker location') }}
                </div>
                <div>{{ room.roomPlan.breakerlocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Gas Meter Type Number') }}
                </div>
                <div>{{ room.roomPlan.gasMeterTypeNumber }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Gas Meter Location') }}
                </div>
                <div>{{ room.roomPlan.gasMeterLocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('IH Stove Type') }}
                </div>
                <div>{{ room.roomPlan.stoveType }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Router Location') }}
                </div>
                <div>{{ room.roomPlan.wifiRouterLocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Accesspoint Location') }}
                </div>
                <div>{{ room.roomPlan.wifiAccesspointLocation }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Key 1') }}
                </div>
                <div>{{ room.roomPlan.wifiKey1 }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Password 1') }}
                </div>
                <div>{{ room.roomPlan.wifiPassword1 }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Key 2') }}
                </div>
                <div>{{ room.roomPlan.wifiKey2 }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Wifi Password 2') }}
                </div>
                <div>{{ room.roomPlan.wifiPassword2 }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Boiler Control Panel Location') }}
                </div>
                <div>{{ room.roomPlan.boilerControlPanelLocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Square Meter') }}
                </div>
                <div>{{ room.roomPlan.squareMeter }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Remarks regarding the bed') }}
                </div>
                <div>{{ room.roomPlan.bedRemarks }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Gateway Location') }}
                </div>
                <div>{{ room.roomPlan.gatewayLocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Other remarks') }}
                </div>
                <div>{{ room.roomPlan.otherRemarks }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-permission="['modify-room']" class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Permission') }}
          </h2>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Room status') }}
              </div>
            </div>
            <div class="column is-narrow">
              <a-switch v-model:checked="status" :loading="submitting" />
            </div>
          </div>
          <hr>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Delete room') }}
              </div>
              <div>{{ $t('delete-desc') }}</div>
            </div>
            <div class="column is-narrow">
              <DelRoom :record="room" @delete="toPropertyRooms">
                <template #handler="{ show }">
                  <a-button type="danger" @click="show">
                    {{ $t('Delete') }}
                  </a-button>
                </template>
              </DelRoom>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Room Details": "Room Details",
    "Room info": "Room info",
    "Edit": "Edit",
    "Room Code": "Room Code",
    "Room Name": "Room Name",
    "Room Type": "Room Type",
    "Capacity": "Capacity",
    "Total Beds": "Total Beds",
    "Single Beds": "Single Beds",
    "Semi-Double Beds": "Semi-Double Beds",
    "Double Beds": "Double Beds",
    "Futons": "Futons",
    "Bedrooms": "Bedrooms",
    "Room Plan": "Room Plan",
    "Room Plan Diagram": "Room Plan Diagram",
    "show image": "show image",
    "Breaker location": "Breaker location",
    "Gas Meter Type Number": "Gas Meter Type Number",
    "Gas Meter Location": "Gas Meter Location",
    "IH Stove Type": "IH Stove Type",
    "Wifi Router Location": "Wifi Router Location",
    "Wifi Accesspoint Location": "Wifi Accesspoint Location",
    "Wifi Key 1": "Wifi Key 1",
    "Wifi Password 1": "Wifi Password 1",
    "Wifi Key 2": "Wifi Key 2",
    "Wifi Password 2": "Wifi Password 2",
    "Boiler Control Panel Location": "Boiler Control Panel Location",
    "Square Meter": "Square Meter",
    "Remarks regarding the bed": "Remarks regarding the bed",
    "Gateway Location": "Gateway Location",
    "Other remarks": "Other remarks",
    "Permission": "Permission",
    "Room status": "Room status",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Delete room": "Delete room",
    "delete-desc": "Once deleted, you cannot recover this room. Please be certain.",
    "Delete": "Delete"
  },
  "ja": {
    "Room Details": "部屋詳細",
    "Room info": "部屋情報",
    "Edit": "編集する",
    "Room Code": "部屋コード",
    "Room Name": "部屋番号",
    "Room Type": "部屋タイプ",
    "Capacity": "収容人数",
    "Total Beds": "総Bed数",
    "Single Beds": "Single Bed数",
    "Semi-Double Beds": "Semi-Double Bed数",
    "Double Beds": "Double Bed数",
    "Futons": "布団Set数",
    "Bedrooms": "ベッドルーム数（独立した寝室数）",
    "Room Plan": "運用情報",
    "Room Plan Diagram": "客室平面図",
    "show image": "写真を表示",
    "Breaker location": "ブレーカー位置",
    "Gas Meter Type Number": "マイコン型番",
    "Gas Meter Location": "マイコン位置",
    "IH Stove Type": "IHコンロ型番",
    "Wifi Router Location": "Wifiルーター設置場所",
    "Wifi Accesspoint Location": "Wifi中継機設置",
    "Wifi Key 1": "Wifi①名",
    "Wifi Password 1": "Wifi①暗証番号",
    "Wifi Key 2": "Wifi②名",
    "Wifi Password 2": "Wifi②暗証番号",
    "Boiler Control Panel Location": "給湯器パネル場所",
    "Square Meter": "平米数",
    "Remarks regarding the bed": "Bedに関する備考",
    "Gateway Location": "ゲートウェイ設置場所",
    "Other remarks": "その他特記事項",
    "Permission": "許可",
    "Room status": "部屋ステータス",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Delete room": "部屋削除",
    "delete-desc": "一度削除するとこの部屋は復旧できません。必ず確認してください。",
    "Delete": "削除"
    }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import roles from '@/components/mixins/roles';
import IconEdit from '@/components/icons/IconEdit';
import DelRoom from '@/views/properties/components/DelRoom';
import EditRoomInfoForm from '@/views/properties/components/EditRoomInfoForm';
import EditRoomCapacityForm from '@/views/properties/components/EditRoomCapacityForm';
import EditRoomPlanForm from '@/views/properties/components/EditRoomPlanForm';

export default {
  name: 'RoomOverview',
  components: {
    EditRoomPlanForm,
    EditRoomCapacityForm,
    EditRoomInfoForm,
    DelRoom,
    Modal,
    IconEdit,
  },
  mixins: [roles],
  data() {
    return {
      submitting: false,
      anshinRooms: [],
    };
  },
  computed: {
    ...mapState('rooms', ['room', 'loading']),
    ...mapState('properties', ['property']),
    status: {
      get() {
        return this.room.isActive;
      },
      set(val) {
        this.$store.commit('rooms/SET_ROOM', { ...this.room, isActive: val });
        this.submitting = true;
        this.$store
          .dispatch('rooms/update', {
            id: this.room.id,
            isActive: Boolean(val),
          })
          .then(() => {
            this.submitting = false;
          })
          .catch(() => {
            this.$store.commit('rooms/SET_ROOM', { ...this.room, isActive: !val });
            this.submitting = false;
          });
      },
    },
  },
  created() {
    if (this.property.anshinAccountId) {
      this.getAnshinAccommodations();
    }
  },
  methods: {
    asyncData() {
      this.$store.dispatch('rooms/get', { id: this.$route.params.rid });
    },
    toPropertyRooms() {
      this.$router.push({ name: 'property-rooms', params: { id: this.$route.params.id } });
    },
    getAnshinAccommodations() {
      this.$store
        .dispatch('rooms/listAnshinAccommodations', {
          accommodationsGroupId: this.property.anshinPropertyId,
          anshinAccountId: this.property.anshinAccountId,
          availableOnly: true,
          includeId: this.room.anshinRoomId,
        })
        .then((resp) => {
          this.anshinRooms = resp.list;
        });
    },
  },
};
</script>

<style scoped></style>
