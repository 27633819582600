<template>
  <div class="section">
    <div v-if="room" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Inspection List') }}
          </h2>
        </div>
        <div v-permission="['modify-inspection']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddInspectionListForm
                :value="room"
                @close="
                  () => {
                    hide();
                    fetchRoomInspections();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Inspection List') }}</a
              >
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #action="{ record }">
          <DelInspection
            v-permission="['modify-property']"
            :record="record"
            action="rooms/delInspectionRoom"
            :payload="getDeletePayload(record)"
            @delete="fetchRoomInspections"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Inspection List": "Inspection List",
"Add Inspection List": "Add Inspection List",
"Inspection Name": "Inspection Name",
},
"ja": {
"Inspection List": "確認箇所リスト",
"Add Inspection List": "確認箇所リストを追加",
"Inspection Name": "確認箇所名",
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import list from '@/views/inspections/mixins/list';
import AddInspectionListForm from '@/views/properties/components/AddInspectionListForm';
import DelInspection from '@/views/inspections/components/DelInspection';

export default {
  name: 'RoomInspections',
  components: {
    AddInspectionListForm,
    Modal,
    DelInspection,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Inspection Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('rooms', ['room', 'loading']),
  },
  watch: {
    $route(route) {
      if (route.name === 'room-inspections') {
        this.fetchRoomInspections();
      }
    },
  },
  mounted() {
    this.fetchRoomInspections();
  },
  methods: {
    fetchRoomInspections() {
      const roomId = this.room ? this.room.id : null;

      return this.fetchData({ roomId });
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchRoomInspections();
    },
    getDeletePayload(record) {
      return {
        roomId: this.room.id,
        listId: record.id,
      };
    },
    rowClicked(record) {
      return {
        onClick: () => {
          // todo
          console.log(record);
        },
      };
    },
  },
};
</script>

<style scoped></style>
