<template>
  <div class="section">
    <div v-if="room" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Facilities') }}
          </h2>
        </div>
        <div v-permission="['add-property']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddFacilityToRoomForm
                :value="room"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Facility') }}
              </a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="room.facilities"
        :loading="isFetching"
        :custom-row="rowClicked"
        row-key="id"
      >
        <!--
          <template #id="{ text }">
          {{ text }}
          </template>
        -->
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #category="{ record }">
          {{ record.category }}
        </template>
        <template #subCategory="{ record }">
          {{ record.subCategory }}
        </template>

        <template #description="{ record }">
          {{ record.description }}
        </template>

        <template #action="{ record }">
          <DelFacility
            v-permission="['modify-property']"
            :record="record"
            action="rooms/update"
            :payload="getDeletePayload(record)"
            @delete="fetchData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"Facilities": "Facilities",
"Add Facility": "Add Facility",
"Facility Name": "Facility Name",
"Category": "Category",
"Sub Category": "Sub Category",
"Description": "Description",
},
"ja":{
"Facilities": "設備",
"Add Facility": "設備追加",
"Facility Name": "設備名",
"Category": "大区分",
"Sub Category": "小区分",
"Description": "詳細",
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import AddFacilityToRoomForm from '@/views/properties/components/AddFacilityToRoomForm';
import DelFacility from '@/views/properties/components/DelFacility';

export default {
  name: 'RoomFacilities',
  components: {
    AddFacilityToRoomForm,
    Modal,
    DelFacility,
  },
  data() {
    return {
      submitting: false,
      list: undefined,
      isFetching: false,
      columns: [
        // {
        //   title: '#',
        //   dataIndex: '_id',
        //   key: '_id',
        //   slots: {customRender: 'id'}
        // },
        {
          title: this.$t('Facility Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Category'),
          dataIndex: 'category',
          slots: { customRender: 'category' },
        },
        {
          title: this.$t('Sub Category'),
          dataIndex: 'subCategory',
          slots: { customRender: 'subCategory' },
        },
        {
          title: this.$t('Description'),
          dataIndex: 'description',
          key: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('rooms', ['room', 'loading']),
  },
  watch: {
    $route(route) {
      if (route.name === 'room-facilities') {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData(route = null) {
      route = route || this.$route;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('rooms/get', { id: route.params.rid })
        .then((resp) => {
          this.list = resp.facilities.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        })
        .catch((err) => console.log(err));
    },
    updateStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('incident/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    getDeletePayload(record) {
      return {
        id: this.room.id,
        facilities: this.room.facilities.filter((r) => r.id !== record.id).map((f) => f.id),
      };
    },
    // eslint-disable-next-line no-unused-vars
    rowClicked(record) {
      return {
        onClick: () => {
          // todo
          // this.$router.push({name: 'property-overview', params: {id: record.id}})
        },
      };
    },
  },
};
</script>

<style scoped></style>
