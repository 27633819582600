<template>
  <div class="user">
    <template v-if="!loading && room">
      <AdminSubMenu v-if="property" :menu="menu" :sub-menu="subMenu">
        {{ room.name }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "Overview": "Overview",
    "Facilities": "Facilities",
    "Inspection List": "Inspection List"
  },
  "ja": {
    "Overview": "概要",
    "Facilities": "設備",
    "Inspection List": "確認箇所リスト"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import checkRole from '@/utils/role';
import checkPermission from '@/utils/permission';
import roles from '@/components/mixins/roles';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'Room',
  components: { AdminSubMenu },
  mixins: [roles],
  computed: {
    menu() {
      return {
        name: 'property-rooms',
        title: this.property.name || '',
      };
    },
    subMenu() {
      const menu = [
        {
          name: 'room-overview',
          title: this.$t('Overview'),
        },
      ];

      if (checkRole(this.ROLES.INTERNAL)) {
        menu.push({
          name: 'room-facilities',
          title: this.$t('Facilities'),
        });
      }

      if (checkPermission(['view-inspection'])) {
        menu.push({
          name: 'room-inspections',
          title: this.$t('Inspection List'),
        });
      }

      return menu;
    },
    childrenRoutes() {
      return ['room-overview', 'room-facilities', 'room-inspections'];
    },
    ...mapState('properties', ['property', 'loading']),
    ...mapState('rooms', ['room']),
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.loading = true;
        await this.$store.dispatch('properties/get', { id: route.params.id });
        await this.$store.dispatch('rooms/get', { id: route.params.rid });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>
