<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit room info') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Code')" name="code">
      <a-input v-model:value="form.code" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Anshin Room')" name="anshinRoomId">
      <a-select v-model:value="form.anshinRoomId" :size="size">
        <a-select-option
          v-for="item in anshinRooms"
          :key="item.accommodationsId"
          :value="item.accommodationsId"
        >
          {{ item.displayName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Neppan! Room ID')" name="neppanRoomId">
      <a-input v-model:value="form.neppanRoomId" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Number')" name="roomNumber">
      <a-input-number
        v-model:value="form.roomNumber"
        :size="size"
        type="text"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>

    <a-form-item
      v-if="value.property.useRoomType"
      class="column is-12"
      :label="$t('Room Type')"
      name="roomTypeId"
    >
      <InputRoomType
        v-model:value="form.roomTypeId"
        :property-id="value.propertyId"
      />
    </a-form-item>
    <a-form-item
      v-else
      class="column is-12"
      :label="$t('Room Type')"
      name="type"
    >
      <a-input v-model:value="form.type" :size="size" type="text" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Building')" name="building">
      <a-input v-model:value="form.building" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Floor')" name="floor">
      <a-input-number
        v-model:value="form.floor"
        :size="size"
        type="text"
        style="width: 100%"
      />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Rakuten Travel ID')" name="rtRoomId">
      <a-select
        v-model:value="form.rtRoomId"
        :size="size"
        mode="tags"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit room info": "Edit room info",
    "Room Code": "Room Code",
    "Room Name": "Room Name",
    "Room Type": "Room Type",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja": {
    "Edit room info": "部屋情報を編集",
    "Room Code": "部屋コード",
    "Room Name": "部屋番号",
    "Room Type": "部屋タイプ",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
import InputRoomType from '@/components/InputRoomType';

export default {
  name: 'EditRoomInfoForm',
  components: {
    InputRoomType,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    anshinRooms: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        code: undefined,
        name: undefined,
        type: undefined,
        anshinRoomId: undefined,
        floor: undefined,
        building: undefined,
        neppanRoomId: undefined,
        roomNumber: undefined,
        roomTypeId: undefined,
        rtRoomId: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = nv[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('rooms/update', { id: this.value.id, ...this.form });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
