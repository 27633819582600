<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Capacity') }}
      </h3>
    </div>
    <a-form-item class="column is-6" :label="$t('Capacity')" name="capacity.capacity">
      <a-input-number v-model:value="form.capacity" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Total Beds')" name="capacity.totalBeds">
      <a-input-number v-model:value="form.totalBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Single Beds')" name="capacity.singleBeds">
      <a-input-number v-model:value="form.singleBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Semi-Double Beds')" name="capacity.semiDoubleBeds">
      <a-input-number
        v-model:value="form.semiDoubleBeds"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Double Beds')" name="capacity.doubleBeds">
      <a-input-number v-model:value="form.doubleBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Futons')" name="capacity.futons">
      <a-input-number v-model:value="form.futons" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Bedrooms')" name="capacity.bedrooms">
      <a-input-number v-model:value="form.bedrooms" style="width: 100%" min="0" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Capacity": "Edit Capacity",
    "Capacity": "Capacity",
    "Total Beds": "Total Beds",
    "Single Beds": "Single Beds",
    "Semi-Double Beds": "Semi-Double Beds",
    "Double Beds": "Double Beds",
    "Futons": "Futons",
    "Bedrooms": "Bedrooms",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja": {
    "Edit Capacity": "収容人数を編集",
    "Capacity": "収容人数",
    "Total Beds": "総Bed数",
    "Single Beds": "Single Bed数",
    "Semi-Double Beds": "Semi-Double Bed数",
    "Double Beds": "Double Bed数",
    "Futons": "布団Set数",
    "Bedrooms": "ベッドルーム数（独立した寝室数）",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
export default {
  name: 'EditRoomCapacityForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        capacity: undefined,
        totalBeds: undefined,
        singleBeds: undefined,
        semiDoubleBeds: undefined,
        doubleBeds: undefined,
        futons: undefined,
        bedrooms: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        keys.forEach((k) => {
          this.form[k] = nv[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.$store.dispatch('rooms/update', { id: this.value.id, ...this.form });
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
